import "../styles/app.scss";
import pluginMethods from "./plugin_methods";

$.fn.cdTimer = function(value) {
	var method = '',
		args   = {};

	if (pluginMethods[value]) {
		args = Array.prototype.slice.call(arguments, 1);
	} else if (typeof value === 'object' || !value) {
		args   = arguments;
		method = 'init';
	} else {
		$.error('Метод с именем '+  value +' не существует для jQuery.cdTimer');
	}

	this.each(function() {
		pluginMethods[method].apply(this, args);
	});

	return this;
};