import $cookie from "./lib/cookie";
import buildRedirectUrl from "./fn/build_redirect_url";
import getWordEnding from "./fn/get_word_ending";

var pluginMethods = {
	init : function(options) {
		var config = $.extend(true, {
			dateEnd:     null,
			redirectURL: null,
			cookieName:  null,
			separator:   '',
			style:       'style1',
		}, options);

		var
			self    = this,
			dateEnd = config.dateEnd;
		
		if (config.cookieName) {
			var cookieTime = $cookie('cdt_date_end_'+ config.cookieName);
			console.log(cookieTime);

			if (!cookieTime) {
				cookieTime = dateEnd.getTime();
				$cookie('cdt_date_end_'+ config.cookieName, cookieTime, {expires: 30, path: '/'});
			}

			dateEnd = new Date(Number(cookieTime));
		}
		
		var interval = setInterval(function() {
			var distance = dateEnd.getTime() - (new Date().getTime());

			if (distance < 0) {
				clearInterval(interval);

				if (config.redirectURL) {
					document.location.href = buildRedirectUrl(config.redirectURL);
				}
				
				return;
			}

			var timer = {
				days:    Math.floor(distance / (1000 * 60 * 60 * 24)),
				hours:   Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
				minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
				seconds: Math.floor((distance % (1000 * 60)) / 1000),
			};

			$(self).html(require('../templates/timer.html.twig')({
				timer:         timer,
				config:        config,
				getWordEnding: getWordEnding,
			}));
		}, 1000);
	},
};

export default pluginMethods;