/**
 * Правильное окончание
 * 
 * @param {number}  num    Число
 * @param {string}  str1   Строка для числа, оканчивающегося на 1, кроме 11
 * @param {string}  str2   Строка для числа, оканчивающегося на [2-4], кроме [12-14]
 * @param {string}  str3   Строка для числа, правила которого не совпадает с предыдущими двумя
 * @param {boolean} addNum Добавить число перед строкой
 */
function getWordEnding(num, str1 = 'рубль', str2 = 'рубля', str3 = 'рублей', addNum = true) {
	num     = Number(num);
	var val = num % 100;

	if (val > 10 && val < 20) return addNum ? (num +' '+ str3) : str3;
	
	val = num % 10;

	if (val === 1) return addNum ? (num +' '+ str1) : str1;
	if (val > 1 && val < 5) return addNum ? (num +' '+ str2) : str2;

	return addNum ? (num +' '+ str3) : str3;
}

export default getWordEnding;