/**
* @example cookie('the_cookie', 'the_value');
* @desc Установка значения cookie.
* @example cookie('the_cookie', 'the_value', { expires: 7, path: '/', domain: 'jquery.com', secure: true });
* @desc Создание cookie со всеми доступными опциями.
* @example cookie('the_cookie', null);
* @desc Удалить cookie, передав значение null в качестве значения. Имейте в виду, что вы должны использовать тот же путь и домен, который использовался при установке файла cookie.
*
* @param {string}      name            Имя cookie.
* @param {string}      value           Значение cookie.
* @param {Object}      options         Объект, содержащий пары ключ/значение для предоставления необязательных атрибутов cookie.
* @param {number|Date} options.expires Целое число, указывающее дату истечения срока действия с этого момента в днях или объект даты.
*                                      Если указано отрицательное значение (например, дата в прошлом), cookie будет удален.
*                                      Если задано значение null или пропущено, cookie будет являться сеансовым и не будет сохранен при выходе из браузера.
* @param {string}      options.path    Путь cookie (по умолчанию: путь к странице, создавшей cookie).
* @param {string}      options.domain  Домен cookie (по умолчанию: домен страницы, создавшей cookie).
* @param {boolean}     options.secure  Если true, то будет установлен атрибут secure cookie, и для передачи файлов cookie потребуется безопасный протокол (например, HTTPS).
* @type undefined
*/
var $cookie = function(name, value, options) {
	if (typeof value != 'undefined') { // name and value given, set cookie
		options = options || {};
		if (value === null) {
			value = '';
			options.expires = -1;
		}
		var expires = '';
		if (options.expires && (typeof options.expires == 'number' || options.expires.toUTCString)) {
			var date;
			if (typeof options.expires == 'number') {
				date = new Date();
				date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
			} else {
				date = options.expires;
			}
			expires = '; expires=' + date.toUTCString(); // use expires attribute, max-age is not supported by IE
		}
		// CAUTION: Needed to parenthesize options.path and options.domain
		// in the following expressions, otherwise they evaluate to undefined
		// in the packed version for some reason...
		var path = options.path ? '; path=' + (options.path) : '';
		var domain = options.domain ? '; domain=' + (options.domain) : '';
		var secure = options.secure ? '; secure' : '';
		document.cookie = [name, '=', encodeURIComponent(value), expires, path, domain, secure].join('');
	} else { // only name given, get cookie
		var cookieValue = null;
		if (document.cookie && document.cookie != '') {
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				var cookie = $.trim(cookies[i]);
				// Does this cookie string begin with the name we want?
				if (cookie.substring(0, name.length + 1) == (name + '=')) {
					cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
					break;
				}
			}
		}
		return cookieValue;
	}
};

export default $cookie;