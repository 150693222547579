/**
 * Сформировать URL для перенаправления
 * 
 * @param {string} url Исходный URL
 * @returns {string} Сформированный URL
 */
function buildRedirectUrl(url) {
	var
		newUrl  = new URL(url),
		pageUrl = new URL(document.location);

	for(let [name, value] of pageUrl.searchParams) {
		newUrl.searchParams.set(name, value);
	}

	return newUrl.href;
};

export default buildRedirectUrl;